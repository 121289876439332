import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { Home } from './Home/Home';

import { msalConfig } from '../utils/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in account and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

export const Routes = () => {
  return (
    <Router.Routes title="USP Ops Reporting">
      <Router.Route path="/" element={<Home instance={msalInstance} />} />
    </Router.Routes>
  );
};
