import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { styled } from '@abyss/web/tools/styled';
import { PublicClientApplication } from '@azure/msal-browser';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  // useMsal,
} from '@azure/msal-react';
import { ThemeProvider } from 'react-bootstrap';
import { Navbar } from '../../components/Navbar';
import { Dashboard } from '../../components/Dashboard';
import { Footer } from '../../components/Footer';
import { BodySubsection } from '../../components/BodySubsection';
import { Login } from '../../common/Login';

const HomeContainer = styled('div', {
  textAlign: 'none',
});

const MainContent = () => {
  // const {instance} = useMsal();

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <HomeContainer>
          <Router.MetaTags title="USP Ops Reporting & Technology" />
          <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
          >
            <Navbar />
            <Dashboard />
            <BodySubsection />
            <Footer />
          </ThemeProvider>
        </HomeContainer>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};

export const Home = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

Home.propTypes = {
  instance: PublicClientApplication.isRequired,
};
