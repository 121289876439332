export const salesforceMenuOptions = [
  {
    label: 'Executive Error Stats',
    target: '_blank',
    url: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com&TargetResource=https%3A%2F%2Fppde.lightning.force.com%2Flightning%2Fn%2FExecutive_Home',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Capacity Planner',
    target: '_blank',
    url: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com&TargetResource=https%3A%2F%2Fppde.lightning.force.com%2Flightning%2Fn%2FCapacity_Planner_Analyst',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Reports - Recent',
    target: '_blank',
    url: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com&TargetResource=https%3A%2F%2Fppde.lightning.force.com%2Flightning%2Fo%2FReport%2Fhome%3FqueryScope%3Dmru',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Duplicate Contract Review',
    target: '_blank',
    url: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com&TargetResource=https%3A%2F%2Fppde.lightning.force.com%2Flightning%2Fo%2FDuplicate_Contract_Review__c%2Flist%3FfilterName%3DRecent',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Operational Stats',
    target: '_blank',
    url: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com&TargetResource=https%3A%2F%2Fppde.lightning.force.com%2Flightning%2Fn%2FOperational_Stats',
    icon: 'pi pi-fw pi-chart-bar',
  },
];

export const powerBIMenuOptions = [
  {
    label: 'Membership Census Detail Report',
    target: '_blank',
    url: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6/reports/156f1546-5b3d-43a2-8ff4-fd8f27d3a0d3/ReportSection31b32b6522eccc9bf18f?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Pre-Termination Provider/Member Claim Activity Report',
    target: '_blank',
    url: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6/reports/f61e66ce-0629-4ed9-a172-b8b50606ddeb/ReportSection8e60a11c6b87d29502db?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Post-Termination Provider/Member Termination Letter Report',
    target: '_blank',
    url: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6/reports/a0387395-ea23-4c3f-aa7c-65450ab66b63/ReportSection84194b073d856d14b46a?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'Oxford Provider Audit Report',
    target: '_blank',
    url: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6/reports/1300f25c-90e6-4dc2-a736-cf9746eaa153/ReportSection84194b073d856d14b46a?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'PCP Roster Report',
    target: '_blank',
    url: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6/reports/8f067fd4-5d4a-4114-ab99-26d8de5668c8/ReportSection?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    icon: 'pi pi-fw pi-chart-bar',
  },
];

export const tableauMenuOptions = [
  {
    label: 'USP Provider Ops Reporting Dashboard',
    target: '_blank',
    url: 'https://orbitbi-tableau.optum.com/#/site/USP/views/PolarisProviderOpsReportingDashboard_Public/USPProviderOpsReportingDashboard?:iid=1',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'USP Provider Daily Dashboard',
    target: '_blank',
    url: 'https://orbitbi-tableau.optum.com/#/site/USP/views/USPProviderDailyDashboard/USPProviderDailyScorecardDashboard?:iid=2',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'USP Provider Claim Inquiries Summary Dashboard',
    target: '_blank',
    url: 'https://orbitbi-tableau.optum.com/#/site/USP/views/ClaimInquiriesSummaryDashboard_Public/ProviderWarRoomSummary?:iid=1',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'USP Provider Claim Edit Dashboard',
    target: '_blank',
    url: 'https://orbitbi-tableau.optum.com/#/site/USP/views/ClaimEditDashboard_Test/ClaimEditReceivedInventory?:iid=1',
    icon: 'pi pi-fw pi-chart-bar',
  },
  {
    label: 'USP Provider Open Inventory Operational Stats',
    target: '_blank',
    url: 'https://orbitbi-tableau.optum.com/#/site/USP/views/PolarisOpenInventoryStatus_Public/OpenInventoryOperationalStatsDashboard?:iid=5',
    icon: 'pi pi-fw pi-chart-bar',
  },
];
