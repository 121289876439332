import React from 'react';
import { IWantToSection } from './IWantToSection';
import { ReleaseCalendar } from './ReleaseCalendar';

// import "./css/IWantToSection.css";

export const BodySubsection = () => {
  return (
    <div className="parent-div">
      <div className="wrapper">
        <h5 className="i-want-to-header">I want to...</h5>
        <div className="inside-wrapper">
          <IWantToSection />
          <ReleaseCalendar />
        </div>
      </div>
    </div>
  );
};
