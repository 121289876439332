import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import {
  IndianHoildays,
  ReleaseDates,
  USAHoildays,
} from '../utils/CalendarDates';

function tileClassName({ date, view }) {
  if (view === 'month') {
    if (
      ReleaseDates.find((releaseDate) => {
        return releaseDate === moment(date).format('DD-MM-YYYY');
      })
    ) {
      return 'release-date-class';
    }
    if (
      IndianHoildays.find((holiday) => {
        return holiday.date === moment(date).format('DD-MM-YYYY');
      })
    ) {
      return 'india-holiday-date-class';
    }
    if (
      USAHoildays.find((holiday) => {
        return holiday.date === moment(date).format('DD-MM-YYYY');
      })
    ) {
      return 'usa-holiday-date-class';
    }
  }
  return '';
}

// function releaseTileContent({ date, view }) {
//     let holidayMatched = {};
//     if(view === 'month') {
//         if(ReleaseDates.find((releaseDate)=> {
//             return releaseDate === moment(date).format("DD-MM-YYYY")
//         })) {
//             return <div>New Release</div>;
//         }if(IndianHoildays.find((holiday)=> {
//             return holiday.date === moment(date).format("DD-MM-YYYY")
//         })) {
//             holidayMatched = IndianHoildays.find((holiday) => {
//                 return holiday.date === moment(date).format("DD-MM-YYYY")
//             });
//             return <div>{holidayMatched.name}</div>
//         }
//     }
// }

// function handleClickonDay(event) {
//    if(ReleaseDates.find((releaseDate)=> {
//        return releaseDate === moment(event).format("DD-MM-YYYY")
//    })) {
//         window.open('https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/RT%20Sprint%20Calendar/calendar.aspx', '_blank')
//    }
// }

export const ReleaseCalendar = () => {
  const [value, setValue] = useState(new Date());
  const onChange = (e) => {
    setValue(e);
  };

  return (
    <div className="relase-calender">
      <Calendar
        calendarType="US"
        value={value}
        onChange={onChange}
        tileClassName={tileClassName}
        // tileContent={releaseTileContent}
        // onClickDay= {handleClickonDay}
      />
      <div className="calendar-legend">
        <div id="release" className="calendar-ib" />
        <div className="calendar-legend-text">Release Day</div>
        <div id="usa-holiday" className="calendar-ib" />
        <div className="calendar-legend-text">US Holidays</div>
        <div id="india-holiday" className="calendar-ib" />
        <div className="calendar-legend-text">India Holidays</div>
      </div>
    </div>
  );
};
