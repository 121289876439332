export const links = {
  tableau: {
    main: 'https://orbitbi-tableau.optum.com/#/site/USP/views/PolarisProviderOpsReportingDashboard_Public/USPProviderOpsReportingDashboard?:iid=1',
    documentation:
      'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Shared%20Documents/Tableau_Dashboard_V2.docx?d=w74731a0711014e62badff71096f6adb7',
    instruction:
      'https://uhgazure.sharepoint.com/:b:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/SOPs/Tableau_How%20to%20Request%20USP%20Access.pdf?csf=1&web=1&e=v9hsbq',
  },
  salesforce: {
    main: 'https://authgateway3.entiam.uhg.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fppde.my.salesforce.com',
    documentation: '',
    instruction:
      'https://uhgazure.sharepoint.com/:b:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/SOPs/Salesforce_How%20to%20Request%20Access%20and%20Maintenance%20SOP.pdf?csf=1&web=1&e=ze3nfV',
  },
  powerbi: {
    main: 'https://app.powerbi.com/groups/me/apps/e9c927d5-3d94-415f-8f30-855bc0cff1f6?ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421&experience=power-bi',
    documentation: '',
    documentationText:
      'This documentation is linked within the menu of Power BI',
    instructionText: 'This documentation is linked within the menu of Power BI',
    instruction:
      'https://uhgazure.sharepoint.com/:b:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/SOPs/Power%20BI_Request%20Access%20to%20USP%20Self%20Service%20Reporting%20Job%20Aid.pdf?csf=1&web=1&e=Qx8iza',
  },
  sops: {
    main: 'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/Forms/AllItems.aspx?csf=1&web=1&e=8QfbcC&cid=1902e4a5%2Ddc52%2D407f%2Dac13%2D76393ebcc9bd&FolderCTID=0x0120006F761141A93C4643B8555FD6A7131A1D&id=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FExternal%20Documentation%2FSOPs&viewid=5ab3bec9%2D25ac%2D4490%2Db578%2Dfe248af6dcde',
  },
  roster: {
    main: 'https://uspprovidercompare.uhc.com/',
    documentation: '',
    documentationText: 'Not avialable for now',
    instructionText: 'Not avialable for now',
    instruction: '',
  },
  servicenow: {
    main: 'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx',
  },
  sharepoint: {
    main: 'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/Forms/AllItems.aspx?csf=1&web=1&e=8QfbcC&cid=1902e4a5%2Ddc52%2D407f%2Dac13%2D76393ebcc9bd&FolderCTID=0x0120006F761141A93C4643B8555FD6A7131A1D&viewid=5ab3bec9%2D25ac%2D4490%2Db578%2Dfe248af6dcde',
  },
  releaseDocuments: {
    main: 'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/Forms/AllItems.aspx?csf=1&web=1&e=8QfbcC&cid=1902e4a5%2Ddc52%2D407f%2Dac13%2D76393ebcc9bd&FolderCTID=0x0120006F761141A93C4643B8555FD6A7131A1D&id=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FExternal%20Documentation%2FRelease%20Communications&viewid=5ab3bec9%2D25ac%2D4490%2Db578%2Dfe248af6dcde',
  },
};
