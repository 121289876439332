export const ReleaseDates = [
  '14-12-2023',
  '11-01-2024',
  '25-01-2024',
  '08-02-2024',
  '22-02-2024',
  '07-03-2024',
  '21-03-2024',
  '04-04-2024',
  '18-04-2024',
  '02-05-2024',
  '16-05-2024',
  '30-05-2024',
  '13-06-2024',
  '27-06-2024',
  '11-07-2024',
  '25-07-2024',
  '08-08-2024',
  '22-08-2024',
  '05-09-2024',
  '19-09-2024',
  '03-10-2024',
  '17-10-2024',
  '01-11-2024', // Diwali
  '14-11-2024',
  '29-11-2024', // thanksgiving holiday
  '12-12-2024',
  '26-12-2024',
];

export const IndianHoildays = [
  {
    date: '25-12-2023',
    name: 'Christmas Day',
  },
  {
    date: '01-01-2024',
    name: "New Year's Day",
  },
  {
    date: '26-01-2024',
    name: 'Republic Day',
  },
  {
    date: '25-03-2024',
    name: 'Holi',
  },
  {
    date: '29-03-2024',
    name: 'Good Friday',
  },
  {
    date: '11-04-2024',
    name: 'Idul Fitr',
  },
  {
    date: '15-08-2024',
    name: 'Independence Day',
  },
  {
    date: '26-08-2024',
    name: 'Janmashtami /Krishna Jayanthi',
  },
  {
    date: '02-10-2024',
    name: 'Mahatma Gandhi Jayanti',
  },
  {
    date: '31-10-2024',
    name: 'Deepawali/Diwali',
  },
  {
    date: '25-12-2024',
    name: 'Christmas Day',
  },
];

export const USAHoildays = [
  {
    date: '25-12-2023',
    name: 'Christmas Day',
  },
  {
    date: '01-01-2024',
    name: "New Year's Day",
  },
  {
    date: '15-01-2024',
    name: 'Martin Luther King Jr. Day',
  },
  {
    date: '27-05-2024',
    name: 'Memorial Day',
  },
  {
    date: '04-07-2024',
    name: 'Independence Day',
  },
  {
    date: '02-09-2024',
    name: 'Labor Day',
  },
  {
    date: '28-11-2024',
    name: 'Thanksgiving Day',
  },
  {
    date: '29-11-2024',
    name: 'Day after Thanksgiving Day',
  },
  {
    date: '25-12-2024',
    name: 'Christmas Day',
  },
];
