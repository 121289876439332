import React, { useState, useEffect } from 'react';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { NavMenu } from '@abyss/web/ui/NavMenu';
import { Container, Row, Col } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import {
  salesforceMenuOptions,
  powerBIMenuOptions,
  tableauMenuOptions,
} from '../utils/NavbarMenuLinks';

/* eslint no-console: ["error", { allow: ["warn", "error", "info", "debug", "log"] }] */

export const Navbar = () => {
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    // console.log(activeAccount);
  }

  const [hours] = useState(new Date().getHours());
  const [username] = useState('Test');

  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon ');
    } else if (hours >= 16) {
      setGreeting('Good evening ');
    }
  }, [greeting, hours]);

  const topMenuItems = [
    {
      title: 'Salesforce Menu',
      content: (
        <NavMenu.Columns>
          <NavMenu.Column>
            {salesforceMenuOptions.map((option, index) => {
              return (
                <NavMenu.Item
                  key={`salesforcemenuitem${index}`}
                  title={option.label}
                  href={option.url}
                />
              );
            })}
          </NavMenu.Column>
        </NavMenu.Columns>
      ),
    },
    {
      title: 'Power BI Menu',
      content: (
        <NavMenu.Columns>
          <NavMenu.Column>
            {powerBIMenuOptions.map((option, index) => {
              return (
                <NavMenu.Item
                  key={`powerbimenuitem${index}`}
                  title={option.label}
                  href={option.url}
                />
              );
            })}
          </NavMenu.Column>
        </NavMenu.Columns>
      ),
    },
    {
      title: 'Tableau Menu',
      content: (
        <NavMenu.Columns>
          <NavMenu.Column>
            {tableauMenuOptions.map((option, index) => {
              return (
                <NavMenu.Item
                  key={`tablemenuitem${index}`}
                  title={option.label}
                  href={option.url}
                />
              );
            })}
          </NavMenu.Column>
        </NavMenu.Columns>
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageHeader
        data-testid="navbar"
        logo={
          <React.Fragment>
            <div
              className="nav-div"
              data-testid="navbar-logo-and-greeting-section"
            >
              <Brandmark
                brand="uhc"
                size="120px"
                affiliate="uhc"
                variant="lockup"
                color="blue"
              />
              <h5 className="greeting">
                Welcome to USP Operations Reporting & Technology
              </h5>
              <h5 className="greeting-secondary">
                {greeting}
                {activeAccount ? `${activeAccount.name} ` : username}{' '}
              </h5>
            </div>
          </React.Fragment>
        }
        fullWidth
        model="pageheader-model"
        topMenuItems={topMenuItems}
        css={{
          'abyss-page-header-root': { zIndex: 209 },
        }}
      >
        <Container>
          <Row className="toolbar">
            {/* <Col sm={12} md={6} lg={4} /> */}

            <Col sm={12} md={6} lg={4} />

            {/* <Col sm={12} md={4} lg={4}>
                  <h5 className="greeting-secondary">{greeting}  </h5>
                  <h3 className="greeting-secondary">{username}</h3>
                </Col> */}
          </Row>
        </Container>
      </PageHeader>
    </React.Fragment>
  );
};
