import { links } from '@src/utils/links';
import React from 'react';

export const IWantToSection = () => {
  return (
    <React.Fragment>
      {/* <NewTinForm openModal={openModal} setOpenModal={setOpenModal} /> */}
      <div className="i-want-to-wrapper">
        <ul className="i-want-to-buttons">
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.sops.main, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Explore our SOPs & Learn About our Apps
              </div>
            </button>
          </li>

          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.tableau.documentation, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Learn About The Tableau App
              </div>
            </button>
          </li>

          {/* <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                // window.open(links.salesforce.documentation, "_blank");
                setOpenModal(true);
              }}
            >
              <div className="shortcut-button-text">
                Add New TIN for Provider Roster
              </div>
            </button>
          </li> */}
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.tableau.instruction, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Request Access to Tableau Dashboards
              </div>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.salesforce.instruction, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Request Access to Salesforce Workflow
              </div>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.powerbi.instruction, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Request Access to Power BI Dashboards/Reports
              </div>
            </button>
          </li>

          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.servicenow.main, '_blank');
              }}
            >
              <div className="shortcut-button-text">Submit an R&T Intake</div>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.sharepoint.main, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                Visit Our SharePoint & Library
              </div>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="shortcut-button"
              onClick={(event) => {
                event.preventDefault();
                window.open(links.releaseDocuments.main, '_blank');
              }}
            >
              <div className="shortcut-button-text">
                View Release Documentation
              </div>
            </button>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
