import React from 'react';
import { PageFooter } from '@abyss/web/ui/PageFooter';

export const Footer = () => {
  return (
    <PageFooter
      css={{
        'abyss-page-footer-root': {
          background: '#002677',
          color: 'white',
          margin: '20px 0px 0px 0px',
        },
        'abyss-page-footer-sub-wrapper': {
          color: '#fff',
        },
        'abyss-page-footer-container': {
          padding: '0px 10px 0px 10px',
        },
      }}
      subFooterTitle="United HealthCare Services, Inc"
    >
      <PageFooter.Section
        css={{
          'abyss-page-footer-section-title': {
            color: 'white',
          },
        }}
      >
        <PageFooter.Link
          css={{
            'abyss-page-footer-link': {
              color: '#fff',
            },
          }}
        >
          <a
            className="footer-links"
            href="https://uhgazure.sharepoint.com/:b:/r/sites/PolarisProviderOperations1/Reporting_and_Technology/External%20Documentation/Presentations/About_USP_Reporting_and_Tech.pdf?csf=1&web=1&e=a7Yxc2"
            target="_blank"
            rel="noreferrer"
          >
            About Us
          </a>
        </PageFooter.Link>
      </PageFooter.Section>
      <PageFooter.Section
        css={{
          'abyss-page-footer-section-title': {
            color: 'white',
          },
        }}
      >
        <PageFooter.Link
          css={{
            'abyss-page-footer-link': {
              color: '#fff',
            },
          }}
        >
          <a
            className="footer-links"
            href="mailto:usp_internal_ops_reporting@uhc.com"
          >
            Contact Us
          </a>
        </PageFooter.Link>
      </PageFooter.Section>
    </PageFooter>
  );
};
